/* autoprefixer grid: autoplace */
:root {
	--fontFamilyBase: 'Inter', sans-serif;

	--primaryDefault: #FF5E45;
	--primaryDefaultHover: #39207C;

	--secondaryDefault: #FFF;
	--secondaryDefaultHover: #FF5E45;
	
	--textWhite: #FFF;
	--textBlack: #1A1A1A;
	--textMidGrey: #595959;
	--textLightGrey: #6A758B;

	--aflCoreDarkBlue: #03061C;
	--aflCoreBlue: #170338;
	--aflCoreDarkNavy: #031745;
	--aflCoreNavy: #01285E;
	--aflCoreSkyBlue: #1FB4FF;
	--aflwBrightPurple: #6C3FCC;
	--aflwChargedCoral: #FF3631;
	--aflwArrowNotActive: #727272;

	--aflwDarkPurple: #2A0466;
	--aflwPurple: #50054B;
	--aflwBlue: #00D4E7;
	--aflwPink: #FE56DE;
	--aflwPurpleDisabled: rgba(57, 32, 124, 0.5);
	--aflwButtonOrange: #FF3B1D;
	--aflwModalHeader: #50054B;
	--aflwBKGPurple: #F8F5FF;
	--aflwLightGreyPurple: #F2F2F7;
	--aflwLightPurPleBackground: #F4F2F7;
	--aflwbkg-light-canvas: #F7F6F2;
	--aflw-canvas: #E6E5D6;
	--aflwDarkPurple: #380335;
	--aflwPrimaryPurple: #1F0419;
	--aflwDisabledButtonColor: rgba(247, 246, 242, 0.2);
	--aflwDisabledButtonHoverColor:  rgba(255, 59, 29, 0.2);
	--aflw-primary-200: #EFEEE4;

	--statusLivePlaying: #20B741;
	--statusErrorRemoveNotPlaying: #FF2214;

	--supportBlack: #1A1A1A;
	--supportGrey: #595959;
	--supportLightGrey: #F2F4F7;

	--inputBorder: #CDCDCD;
	--inputLabel: #808990;
	--inputLabelActive: #1F1F1F;
	--inputCheck: #39207C;
	--removeRed: #E32525;

	--maxWidthHeader: 1467px;
	--maxWidthContent: 1307px;
}

html, body {
	font-family: var(--fontFamilyBase);
	min-height: 100%;
	height: 100%;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

p {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.2px;
}

strong {
	font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
	font-weight: 700;
}

h1 {
	font-size: 24px;
	line-height: 120%;
	color: var(--aflwPurple);
	margin-bottom: 20px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
	font-size: 18px;
	line-height: 20px;
}

h6 {
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

a {
    text-decoration: underline;
}

/*
* remove outline:none setting
* to help with A11Y.
*/
input,
button,
select {
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

/**
allow for fullwith components on mobile
*/
.fullwidth-container section {
	width: 100%;
	padding: 0;
}